.folderCard {
  height: 94px;
  margin-right: 16px;
  cursor: pointer;
  background-color: #1976d20a;
  transition: background-color 0.3s ease;
}

.folderCardSelected {
  background-color: #1976d21f;
}

.folderCard:hover {
  background-color: #1976d21f;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  margin-bottom: 3px;
}

.editActions {
  align-self: flex-start;
}
