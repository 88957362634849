.searchBox {
  margin-top: 16px;
  width: 100%;
}

.textField {
  padding: 7px 7px 7px 0;
  width: 600px;
  box-sizing: border-box;
}

.inputRoot {
  height: 40px;
}

.inputRoot:disabled {
  background-color: #f5f5f5;
}

.searchButton {
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
