.saveButton {
  max-width: 70px;
}

.textField {
  margin-right: 24px;
  flex: 1;
}

.cancelButton {
  max-width: 92px;
  margin-left: 8px;
  border-color: #1976d280;
  color: var(--mui-palette-primary-main);
}

.title {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
}

.addFolderBox {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.content {
  display: flex;
  align-items: center;
}
