.searchBox {
  height: 40px;
  margin-left: auto;
}

.container {
  padding: 2px;
  display: flex;
  justify-content: space-between;
  min-height: 49px;
}

.tools {
  display: flex;
  align-items: center;
}

.sortButton {
  margin-left: 20px;
}
