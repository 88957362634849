.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 95%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchIcon {
  width: 70px;
  height: 70px;
  color: var(--text-secondary);
}

.description {
  margin-top: 8px;
  max-width: 95%;
  text-align: center;
}
