.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #16a9f7;
}

.content {
  width: 50%;
}

.emailSentContent {
  width: 90%;
}

.form {
  width: 100%;
}

.paper {
  padding: 80px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton {
  margin-top: 20px;
}

.fadeIn {
  animation: fadeInAnimation 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
