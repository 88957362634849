.root {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding: 24px 0px 16px 24px;
  display: flex;
}

.header {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 275px;
}

.tab {
  min-width: 100px;
  font-weight: 500;
  color: #0000008a;
}

.selectedTab {
  color: #1976d2;
}

.indicator {
  background-color: #1976d2;
  height: 2px;
}
