.tableCell {
  height: 400px;
  position: relative;
}

.contentBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.searchIcon {
  width: 70px;
  height: 70px;
  color: var(--text-secondary);
}
