.container {
  min-width: 272px;
  padding: 14px 16px;
}

.tagRow {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.tag {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addTagButton {
  border-radius: 20px;
  border-style: dashed;
  text-transform: none;
  padding: 6px 16px;
  margin-bottom: 16px;
  width: 100%;
}
