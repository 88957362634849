.container {
  padding: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.textWithIcon {
  display: flex;
  align-items: center;
}
