.container {
  display: flex;
  height: 100%;
}

.drawer {
  width: 300px;
  flex-shrink: 0;
}

.title {
  text-align: center;
  margin-bottom: 4px;
}

.drawerPaper {
  width: 300px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.startButton {
  margin: 16px 16px 36px 16px;
}

.startButton:hover {
  background-color: var(--mui-palette-primary-main);
  color: #fff;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.searchHistory {
  overflow: auto;
  flex-grow: 1;
}

.mainContent {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 24px;
}
