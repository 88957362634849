.dataRow {
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dataRow:hover {
  background-color: #1976d20a;
}

.itemCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 12px;
  background-color: #f5f5f5;
  margin-top: 8px;
}

.itemTypeIcon {
  height: 18px;
  width: 18px;
}

.nameLabel {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
}
