.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #16a9f7;
  padding: 60px;
}

.paper {
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.updatedPasswordContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.updatedPasswordDescription {
  margin-bottom: 30px;
  text-align: center;
}

.codeContainer {
  text-align: center;
  margin-bottom: 50px;
}

.codeLabel {
  margin-bottom: 24px;
}

.passwordLabel {
  margin-bottom: 8px;
}

.fadeIn {
  animation: fadeInAnimation 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
