.title {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
  padding-bottom: 8px;
}

.content {
  display: flex;
  align-items: center;
}

.step {
  color: #9e9e9e;
}
