.container {
  padding: 0px 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title {
  width: 60%;
  margin-right: 16px;
  font-size: 23px;
}

.status {
  width: 290px;
  justify-content: start;
  border-radius: 5px;
  margin-right: 24px;
}

.details {
  margin-top: 16px;
  padding: 16px;
  border: 1px solid grey;
  border-color: grey;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
