.fileAttachTip {
  font-size: 12px;
  color: #9e9e9e;
  margin-bottom: 18px;
}

.fileAttachTipError {
  color: #ff5252;
}

.noMarginBottom {
  margin-bottom: 0;
}

.submitButton {
  position: absolute;
  right: 16px;
  bottom: -30px;
}

.attachButton {
  position: absolute;
  left: 16px;
  bottom: -30px;
}

.form {
  position: relative;
  height: 230px;
  margin-bottom: 55px;
}
