.icon {
  margin-right: 14px;
  margin-left: 3px;
}
.accountMenu {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 16px;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.user {
  pointer-events: none;
  padding-top: 12px;
  margin-bottom: 9px;
}

.queueLengthContainer {
  pointer-events: none;
}

.menuPaper {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  margin-top: 12px;
  position: relative;
}
