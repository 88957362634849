.container {
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.ruleBox {
  display: flex;
  align-items: center;
}

.ruleText {
  margin-left: 8px;
}
