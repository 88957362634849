.header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleInput {
  width: 100%;
  margin-bottom: 16px;
}

.colorGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  max-width: 300px;
}

.colorBox {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 16px;
}

.checkIcon {
  color: #fff;
  font-size: 24px;
}
