.dialogPaper {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 100vh;
  bottom: 24px;
  margin: 0;
  border-radius: 0;
}

.titleBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
}
