.status {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 1px;
  cursor: pointer;
}

.statusMatrixContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.description {
  margin-top: 16px;
}

.statusInfoContainer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.statusInfo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.statusInfoLegend {
  width: 10px;
  height: 10px;
  margin-right: 8px;
}
