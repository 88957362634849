.actions {
  display: flex;
  flex-direction: column;
}

.container {
  border: 1px solid rgba(224, 224, 224, 1);
  position: relative;
  box-shadow: none;
}

.tableHeader {
  height: 70px;
}

.updateButton {
  margin-bottom: 8px;
}

.resendButton {
  margin-bottom: 8px;
  background-color: #979797;
  color: #fff;
}

.resendButton:hover {
  background-color: #818181;
}
