.loginBackground {
  transition: background-color 1s ease;
  background-color: #16a9f7;
}

.mainContainer {
  height: 100vh;
}

.gridContainer {
  height: 100%;
}

.icon {
  font-size: 300px;
  color: #fff;
}

.paper {
  padding: 32px;
  width: 100%;
  max-width: 400px;
}

.submitButton {
  margin-top: 24px;
}

.forgotPassword {
  margin-top: 16px;
  text-align: center;
}
