.appBar {
  z-index: 2;
}

.logoContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  display: flex;
  align-items: center;
}

.infoBlock {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
  font-size: 1.25rem;
}
