.dialogContainer {
  padding-top: 24px;
}

.content {
  padding: 16px 16px 0 16px;
}

.title {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
  padding-left: 0;
  padding-top: 0;
}

.description {
  margin-bottom: 18px;
}

.list {
  padding-top: 0;
}

.folder {
  background-color: #1976d20a;
}

.folder:hover {
  background-color: #1976d21f;
}

.selectedFolder {
  background-color: #1976d21f;
}

.folderSuccess {
  background-color: #e8f5e9;
}

.folderSuccess:hover {
  background-color: #e8f5e9;
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.folderIcon {
  margin-right: 8px;
}

.folderCheckIcon {
  color: #4caf50;
}

.addIcon {
  color: white;
}

.saveButton {
  max-width: 70px;
}

.textField {
  margin-right: 24px;
  flex: 1;
}

.cancelButton {
  max-width: 92px;
  margin-left: 8px;
  border-color: #1976d280;
  color: var(--mui-palette-primary-main);
}

.addFolderBox {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.loadingBox {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
}

.addFolderButton {
  letter-spacing: 1.25px;
  padding: 9px 16px;
}

.footer {
  padding: 16px 16px 16px 0;
}
