.container {
  position: relative;
  box-shadow: none;
  padding-top: 16px;
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.content {
  border: 1px solid rgba(224, 224, 224, 1);
}
