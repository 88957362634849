.container {
  position: relative;
  box-shadow: none;
  border: 1px solid rgba(224, 224, 224, 1);
}

.titleIcon {
  margin-right: 16px;
}

.title {
  flex-grow: 1;
}

.actions {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.addToWorkspaceButton {
  margin-bottom: 16px;
}
