.actionsContainer {
  display: flex;
  margin-bottom: 24px;
}

.priorityContainer {
  padding: 16px 24px 8px 24px;
  width: 36%;
  border-right: 1px solid #e0e0e0;
}

.tagContainer {
  padding: 16px 24px 24px 24px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tagLabel {
  margin-bottom: 7px;
  font-weight: 700;
  color: grey;
}
