.tableCell {
  cursor: pointer;
}

.iconButtonContainer {
  width: 30px;
  display: flex;
  justify-content: center;
}

.tableCellContent {
  display: flex;
  align-items: center;
  min-height: 36px;
}
