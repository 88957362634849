input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 20px 0;
}

.content {
  margin-top: 100px;
  text-align: center;
}

.codeLabel {
  margin-bottom: 24px;
}

.passwordLabel {
  margin-bottom: 24px;
}
