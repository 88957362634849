.buttonContainer {
  display: flex;
  width: 100%;
  padding: 10px 16px;
}

.button {
  border-radius: 20px;
  border-style: dashed;
  text-transform: none;
  padding: 6px 16px;
  width: 100%;
}
