.container {
  padding: 24px;
}

.foldersRow {
  display: flex;
  margin-bottom: 24px;
}

.addFolderButton {
  height: 94px;
  width: 56px;
  margin-right: 16px;
  border-style: dashed;
}

.folders {
  display: flex;
  overflow-x: auto;
}
