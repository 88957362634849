.dialogPaper {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 100vh;
  bottom: 24px;
  margin: 0;
  border-radius: 0;
}

.footer {
  padding: 16px 22px 16px 0;
}

.moreInsightsContainer {
  padding: 24px;
  background-color: #e8f5e9;
  border-radius: 8px;
}
