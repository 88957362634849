.statusChip {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 8px 16px;
  align-self: center;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.closeButton {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.closeIcon {
  color: #9e9e9e;
  width: 20px;
  height: 20px;
}
