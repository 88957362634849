.generateButton {
  background-color: #979797;
  color: white;
  margin-right: 24px;
}

.generateButton:hover {
  background-color: #818181;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 30vw;
}

.submitButton {
  align-self: center;
}
